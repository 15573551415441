import * as React from 'react';
import { DetailsListLayoutMode, Selection, IColumn, IDetailsHeaderProps, IDetailsColumnRenderTooltipProps, IDetailsListProps, IDetailsRowStyles, DetailsRow, SelectionMode, 
  ThemeProvider, Spinner, SpinnerSize, DialogContent, ComboBox, IComboBox, IComboBoxOption, Icon, FocusTrapCallout, Stack } from '@fluentui/react';
import { getId, MarqueeSelection, ScrollablePane, ScrollbarVisibility, Sticky, StickyPositionType, IRenderFunction, TooltipHost, mergeStyleSets } from '@fluentui/react';
import { Announced } from '@fluentui/react';
import { EntityStringDto, NamedEntityStringDto } from '../../../services/dto/entityStringDto';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react';
import { PrimaryButton, DefaultButton } from '@fluentui/react';
import { CommandBarBase, ICommandBarBaseProps, ICrudPermissons } from '../../BaseComponents/commandBarBase';
import { CrudConsts } from '../../../stores/crudStoreBase';
import { L } from '../../../lib/abpUtility';
import { ShimmeredDetailsList } from '@fluentui/react';
import {additionalTheme, myTheme} from '../../../styles/theme';
import { Container } from '../../../stores/storeInitializer';
import { asyncForEach, catchErrorMessage, dateFormat, isJsonString } from '../../../utils/utils';
import { ITableColumn } from '../../BaseComponents/ITableColumn';
import { ITableProps } from '../../BaseComponents/ITableProps';
import { ITableState } from '../../BaseComponents/ITableState';
import { GenericPanel, IGenericPanelProps } from './genericPanel';
import { ModalTypes } from '../../BaseComponents/controls';
import { utilMapToColumn } from '../../../utils/tableUtils';
import { getPartialModel } from '../../../utils/modelUtils';
import moment from 'moment';
import { LabeledTextField } from '../../../components/LabeledTextField';
import CheckboxTree from 'react-checkbox-tree';

const classNames = mergeStyleSets({
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 3% 0',
    color: myTheme.palette.neutralDark,
  },
  dialogContent: {
    selectors: {
      '& .icon': {
        color: additionalTheme.lightBlue
      },
    }
  },
  selectionDetails: {
    display:'none',
    minHeight: '30px',
    height: 'auto',
    padding: '5px 3% 5px 60px',
    background: 'transparent',
    selectors: {
      '& > div': {
        height: 'auto',
        width: '100%',
      }
    }
  },
  tableWrapper: {

    selectors: {
      '& .ms-DetailsHeader': {
        color: myTheme.palette.neutralDark,
        paddingTop: 0,
        background: myTheme.palette.neutralLighter,
        textTransform: 'uppercase',
      },
      '& .ms-DetailsHeader-cell.ms-DetailsHeader-cellIsCheck .is-checked.ms-Check:before': {
        background: myTheme.palette.themePrimary,
      },
    }
  },
  checkboxCell: {
    selectors: {
      '& .is-checked.ms-Check:before': {
        background: myTheme.palette.themePrimary,
      },

    }
  },
  paginationFooter: {
    width: "100%",
    height: 50,
    backgroundColor: "transparent",
  },
  smallLoadSpinner: {
    position: 'absolute',
    top: '100px',
    left: 0,
    selectors: {
        '& .ms-Spinner-label': {
            color: myTheme.palette.themePrimary,
        }
    }
  },
  bottomPositionedLoadSpinner: {
    top: 'unset',
    bottom: '15px',
  },
  rowStyle: {
    userSelect: "auto",
    backgroundColor: additionalTheme.white,
    color: myTheme.palette.black,
    ':hover .rowStyle': {
      backgroundColor:'myTheme.palette.themePrimary',
    }
  },
  customScrollablePane: {
    selectors: {
      '& .ms-DetailsList.is-horizontalConstrained': {
        overflow: 'unset',
      }
    }
  },
  columnFilterCallout: {
    minWidth: 400,
    width: 'auto',
    padding: '20px 24px',
  },
  columnFilterCalloutButtonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  }
});

export abstract class FluentTableBase<
    TDto extends EntityStringDto,
    Props extends ITableProps<TDto> = ITableProps<TDto>,
    State extends ITableState<TDto> = ITableState<TDto>
  > extends React.Component<Props, State> {
  private _selection: Selection;
  private _labelId: string = getId('dialogLabel');
  private _subTextId: string = getId('subTextLabel');
  private _blockGetAllRequest: boolean = false;
  private _getAllProceded: boolean = false;
  protected _footerRef: React.RefObject<HTMLDivElement>;

  // private getAllWithDebounce = debounce(this.props.getAll ? this.props.getAll : this.getAll, 400, this);
  protected disableGetAllOnMount: boolean = false;

  private _handleObserver = (entry: any, observer: any) => {
    if(this.props.store && this.props.store.dataSet && this.props.store.dataSet.totalCount > this.props.store.dataSet.items.length && 
      entry[0].isIntersecting && !this.state.asyncActionInProgress && (!this.props.customData || !this.props.customData.useOnlyRefreshItems)
    ) {
      this.getAll(undefined, undefined, this.state.skipCount + this.state.maxResultCount);
    }
  };

  private _observer = new IntersectionObserver(this._handleObserver, {
    root: null,
    rootMargin: "200px",
    threshold: 0.25,
  });

  constructor(props: Props) {
    super(props);

    if(props.customSelection) {
      this._selection = props.customSelection;
    } else {
      this._selection = new Selection({
        selectionMode: this.hasAnyCrudPermission() ? (typeof this.props.selectionMode === 'number' ? this.props.selectionMode : SelectionMode.multiple) : SelectionMode.none,
        onSelectionChanged: () => {
          if(props.customOnSelectionChanged) {
            props.customOnSelectionChanged(this._selection.getSelection());
          }
          this.setState({ selectionDetails: this._getSelectionDetails() });
        },
      });
    }
    
    this._footerRef = React.createRef();

    this.state = {
      ...this.state,
      allItems: props.items ? props.items : [],
      pagedAllItems: props.items ? props.items : [],
      isModalSelection: false,
      isCompactMode: false,
      showModal: false,
      isDraggable: false,
      showDialog: false,
      showFiltersDialog: false,
      dialogEntities: [0],
      dialogEntityId: 0,
      maxResultCount: CrudConsts.LAZY_LOAD_PAGE_SIZE,
      modalVisible: false,
      skipCount: 0,
      filter: undefined,
      prevFilter: '',
      prevCustomRequest: undefined,
      selectionDetails: this._getSelectionDetails(),
      dialogAction: this.delete,
      dialogTitle: L('Do you want to delete these item?'),
      isBulkOperation: false,
      isBusy: false,
      isShimmerEnabled: (props.items && props.items.length > 0) || (this.props.customData && this.props.customData.disableShimmer === true) ? false : true,
      asyncActionInProgress: false,
      overrideItemsTriggerCounter: 0,
      currentSorting: undefined,
      currentOrderBy: undefined,
      currentFilters: {},
      currentFiltersOptions: {},
      currentFiltersSearchValues: [],
      prevFiltersSearchValues: '',
      toggleColumnFilterDialogForIds: {},
      customData: {},
    };
  }

  private mapToColumn(tableColumns: ITableColumn[]) {
    return utilMapToColumn(tableColumns, this._onColumnClick);
  }

  async componentDidMount() {
    if(!this.disableGetAllOnMount) {
      await this._getAllInternal();
    }

    if (this._footerRef.current !== null) {
      this._observer.observe(this._footerRef.current);
    }

    this._openDialogFromNavigation();

    if(this.props.history && this.props.history.location && !!this.props.history.location.pathname) {
      const scrollElement: any = document.querySelector(`#${this.props.history.location.pathname.replaceAll('/', '')}-additional-scrollbar`);
      const listScrollElement: any = document.querySelector('.ms-ScrollablePane--contentContainer');

      if(scrollElement && !!scrollElement) {
        scrollElement.addEventListener('scroll', (event: any) => {
          listScrollElement?.scrollTo(event.srcElement.scrollLeft, listScrollElement.scrollTop);
        });
      }

      if(listScrollElement && !!listScrollElement) {
        listScrollElement.addEventListener('scroll', (event: any) => {
          scrollElement.scrollTo(event.srcElement.scrollLeft, 0);
        });
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if(this.state.isShimmerEnabled && ((this.props.items && this.props.items.length > 0) || (this._getAllProceded && this.state.allItems.length === 0) || 
      (this.props.customData && this.props.customData.disableShimmer === true))
    ) {
      this.setState({isShimmerEnabled: false});
    }

    if((this.props.customData && this.props.customData.overrideAllItemsTrigger) && this.props.customData.overrideAllItemsTrigger !== this.state.overrideItemsTriggerCounter) {
      this.overrideAllItems(this.props.store.dataSet.items, this.props.customData.overrideAllItemsTrigger);
    }
    
    if(this.state.currentOrderBy !== prevState.currentOrderBy || this.state.currentSorting !== prevState.currentSorting) {
      this.getAll();
    }
  }

  componentWillUnmount() {
    if (this._footerRef.current !== null) {
      this._observer.unobserve(this._footerRef.current);
    }

    if(this.props.history && this.props.history.location && !!this.props.history.location.pathname) {
      const scrollElement: any = document.querySelector(`#${this.props.history.location.pathname.replaceAll('/', '')}-additional-scrollbar`);
      const listScrollElement: any = document.querySelector('.ms-ScrollablePane--contentContainer');
      
      if(scrollElement && !!scrollElement) {
        scrollElement.removeEventListener('scroll', (event: any) => {
          listScrollElement?.scrollTo(event.srcElement.scrollLeft, listScrollElement.scrollTop);
        });
      }

      if(listScrollElement && !!listScrollElement) {
        listScrollElement.removeEventListener('scroll', (event: any) => {
          scrollElement.scrollTo(event.srcElement.scrollLeft, 0);
        });
      }
    }
  }

  private _openDialogFromNavigation() {
    let urlParams = new URLSearchParams(window.location.search);
    let id = urlParams.get("id");
    if (id) {
      let numberId = id;
      if (numberId) {
        let index = this.state.allItems.findIndex(x => x.id === numberId);
        if (index !== -1)
          this.createOrUpdateModalOpen({ id: numberId } as TDto);
      }
    }
  }

  protected selectionSetAllSelected(bool: boolean) {
    this._selection.setAllSelected(bool);
  }

  protected overrideAllItems(items: any[], overrideItemsTriggerCounter?: number) {
    if(items && Array.isArray(items)) {
      this.setState({ 
        allItems: items,
        pagedAllItems: items,
        overrideItemsTriggerCounter: (!overrideItemsTriggerCounter || isNaN(overrideItemsTriggerCounter)) ? this.state.overrideItemsTriggerCounter : overrideItemsTriggerCounter 
      });
    }
  }

  protected overrideFilter(text: string) {
    if(typeof text === 'string')
      // this.setState({ filter: text });
      this.getAll(undefined, text, undefined);
  }

  async getAll(customRequest: any | undefined = undefined, filter: string | undefined = undefined, customSkipCount: number | undefined = undefined) {
    if(this.state.asyncActionInProgress || this._blockGetAllRequest === true) return;
    this._blockGetAllRequest = true;
    this.forceUpdate();

    const customRequestExist: boolean = this.props.customData && this.props.customData.customRequest ? true : false;
    const searchText: string = !!filter ? filter : (this.props.searchText ? this.props.searchText : (this.state.filter ? this.state.filter : ''));
    const shouldResetSkipCount: boolean = (customRequestExist && JSON.stringify(this.props.customData!.customRequest) !== this.state.prevCustomRequest) || (searchText !== this.state.prevFilter) ? true : false;

    this.setState({ asyncActionInProgress: true, showFiltersDialog: false });

    // const newMaxResultCount: number = shouldResetSkipCount ? CrudConsts.LAZY_LOAD_PAGE_SIZE : (customSkipCount ? this.state.maxResultCount + customSkipCount : this.state.maxResultCount + this.state.skipCount);
    const newSkipCount: number = shouldResetSkipCount ? 0 : (customSkipCount ? customSkipCount : this.state.skipCount);
    const sortingString: string = !!this.state.currentOrderBy ? (!!this.state.currentSorting ? `${this.state.currentOrderBy}+${this.state.currentSorting}` : `${this.state.currentOrderBy}+asc`) : '';

    let filtersString: string = '';
    if(this.state.currentFilters && Object.keys(this.state.currentFilters).length > 0) {
      let tempFiltersArray: any[] = [];
      for(let key in this.state.currentFilters) {
        if(this.state.currentFilters.hasOwnProperty(key) && !!this.state.currentFilters[key]) {
          tempFiltersArray.push({name: key, value: this.state.currentFilters[key]});
        }
      }

      filtersString = JSON.stringify(tempFiltersArray);
    }

    await this.props.store.getAll(
      !!customRequest ? {...customRequest, keyword: searchText, skipCount: newSkipCount, sorting: sortingString, filters: filtersString} :
        (!!this.state.prevCustomRequest ? {...JSON.parse(this.state.prevCustomRequest), keyword: searchText, skipCount: newSkipCount, sorting: sortingString, filters: filtersString} :
          {keyword: searchText, skipCount: newSkipCount, maxResultCount: this.state.maxResultCount, sorting: sortingString, filters: filtersString})
    ).then().catch((error: any) => {
      console.error(error);
    });

    let tempDataSetItems: any[] = this.props && this.props.store && this.props.store.dataSet && this.props.store.dataSet.items ? this.props.store.dataSet.items : [];

    this.setState({ pagedAllItems: [...tempDataSetItems], 
                    allItems: [...tempDataSetItems], 
                    asyncActionInProgress: false, 
                    filter: searchText, prevFilter: searchText,
                    prevCustomRequest: customRequestExist ? JSON.stringify(this.props.customData!.customRequest) : this.state.prevCustomRequest, 
                    skipCount: newSkipCount, 
                    isShimmerEnabled: false, 
                    // maxResultCount: newMaxResultCount,
                  });
    this._blockGetAllRequest = false;
    this._getAllProceded = true;
    this.forceUpdate();
  }

  static getDerivedStateFromProps(props: any, state: any) {
    const objToUpdate: any = { ...state, items: props.items, allItems: props.items };
    if(state.pagedAllItems.length === 0 && typeof state.filter === 'undefined') {
      objToUpdate.pagedAllItems = props.items;
    }
    if(props.searchText) {
      objToUpdate.filter = props.searchText;
    }

    return objToUpdate;
  }

  // componentWillReceiveProps(props: any) {
  //   this.setState({ items: props.items, isShimmerEnabled: false, allItems: props.items, filter: props.searchText });
  // }

  showDialog(input: TDto[], action?: (input: TDto, values?: any) => Promise<void>, title?: string) {
    this.setState({ dialogEntities: input, dialogAction: action ?? this.delete, dialogTitle: title ?? L('Do you want to delete these item?') });
    this._showDialog();
  }

  delete = async (input: EntityStringDto) => {
    this.setState({ asyncActionInProgress: true });
    this._selection.setAllSelected(false);
    const filteredEntityStringDto: any = getPartialModel(input, ['id']);
    await this.props.store.delete(filteredEntityStringDto as TDto);
    this.setState({ asyncActionInProgress: false });
    // if(!this.state.isBulkOperation) {
    //   await this.props.store.getAll({...this.props.store.defaultRequest, maxResultCount: this.state.maxResultCount});
    //   this.setState({ pagedAllItems: [], asyncActionInProgress: false });
    // } else {
    //   this.setState({ asyncActionInProgress: false });
    // }
    this.getAll();
  };

  update = async (element: TDto, values: any) => {
    this.setState({ asyncActionInProgress: true });
    // fill default values
    let defaultModel = this.props.store.model;
    Object.keys(element).forEach((property) => {
      element[property] = element[property] || defaultModel[property];
    });
    // set form values
    Object.keys(values).forEach((property) => {
      element[property] = values[property];
    });
    // send all
    await this.props.store.update({ ...element, id: element.id });
    await this.props.store.getUpdated([element.id]);
    this.setState({ asyncActionInProgress: false });
  }

  async bulkOperation(entities: TDto[], action?: (input: TDto, values?: any) => Promise<void>) {
    this.props.store.createDefault();
    this.setState({ dialogEntities: entities, dialogAction: action ?? this.update })
    this.Modal(true);
  }

  async createOrUpdateModalOpen(entityStringDto: TDto) {
    if (entityStringDto.id === '') {
      this.props.store.createDefault();
    } else {
      const filteredEntityStringDto: any = getPartialModel(entityStringDto, ['id']);
      await this.props.store.get(filteredEntityStringDto);
    }

    this.setState({ dialogEntityId: entityStringDto.id });
    this.Modal();
    // todo add something as a model
    // this.formRef.props.form.setFieldsValue({
    //   ...this.props.store.model,
    // });
  }

  Modal = (isBulkOperation: boolean = false) => {
    Container.EventBus.customErrorHandling = true;
    this.setState({
      modalVisible: !this.state.modalVisible,
      isBulkOperation: isBulkOperation
    });
  };

  async createOrUpdateBulk(values: any) {
    let dtos = this.state.dialogEntities;
    dtos.forEach(async element => {
      await this.state.dialogAction(element, values);
    });

    // await this.props.store.getAll({...this.props.store.defaultRequest, maxResultCount: this.state.maxResultCount});
    // this.setState({ pagedAllItems: [] });
    this.getAll();
  }

  async createOrUpdate(values: any) {
    let result: any = true;

    if (this.state.isBulkOperation) {
      await this.createOrUpdateBulk(values);
    } else {
      if (this.state.dialogEntityId === '') {
        await this.props.store.create(values).then(async (response: any) => {
          if(!!response && (!response.hasOwnProperty('result') || typeof response.result !== 'undefined')) {
            if(response.id && response.id > 0) {
              await this.props.store.getUpdated([response.id]);
            } else {
              // await this.props.store.getAll({...this.props.store.defaultRequest, maxResultCount: this.state.maxResultCount});
              // this.setState({ pagedAllItems: [] });
              // this.getAll();
            }
            this.getAll();
          } else {
            result = false;
          }
        }).catch((error: any) => {
          result = catchErrorMessage(error);
          console.error(result);
        });
      } else {
        if(this.props.store.crudService) {
          await this.props.store.crudService.update({ id: this.state.dialogEntityId, ...values }).then(async (response: any) => {
            if(!!response && (!response.hasOwnProperty('result') || typeof response.result !== 'undefined')) {
              await this.props.store.getUpdated([this.state.dialogEntityId]);
              this.getAll();
            } else {
              result = false;
            }
          }).catch((error: any) => {
            result = catchErrorMessage(error);
            console.error(result);
          });
        } else {
          await this.props.store.update({ id: this.state.dialogEntityId, ...values }).then(async (response: any) => {
            await this.props.store.getUpdated([this.state.dialogEntityId]);
            this.getAll();
          }).catch((error: any) => {
            result = catchErrorMessage(error);
            console.error(result);
          });
        }
      }
    }

    return result;
  }

  closeModal(calledFromButton: boolean) {
    if (Container.EventBus.HttpError && !calledFromButton) {
      return;
    }
    Container.EventBus.customErrorHandling = false;
    this.setState({ modalVisible: false });
  }

  async onDialogYesClick() {
    try {
      if (!this.state.isBusy) {
        this.setState({ isBusy: true });
        let isClosed = await this._closeDialogAsync();
        if (isClosed) {
          this.setState({ dialogYesButtonDisabled: true }, async () => {
            await asyncForEach<TDto>(this.state.dialogEntities, async (x) => {
              await this.state.dialogAction(x);
            });
            isClosed = await this._closeDialogAsync();
            if (isClosed) this.setState({ dialogYesButtonDisabled: false });
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isBusy: false });
    }
  }

  getItems() {
    // return this.onFilter(this.state.pagedAllItems && this.state.pagedAllItems.length > 0 ? this.state.pagedAllItems : this.state.allItems, this.state.filter || '');
    return this.onFilter(this.state.pagedAllItems ? this.state.pagedAllItems : [], this.state.filter || '');
  }

  public render() {
    if(((!!this.props.searchText && this.props.searchText !== this.state.prevFilter) || 
      (this.props.customData && this.props.customData.customRequest && JSON.stringify(this.props.customData.customRequest) !== this.state.prevCustomRequest)) && 
      !this.state.asyncActionInProgress
    ) {
      const searchText: string = this.props.searchText ? this.props.searchText : (this.state.filter ? this.state.filter : '');
      this.setState({ filter: searchText, prevFilter: searchText });

      if(this.props.refreshItems && typeof this.props.refreshItems === 'function' && this.props.customData && this.props.customData.useOnlyRefreshItems) {
        this.props.refreshItems();
      } else if(this.props.customData && (this.props.customData.customRequest || this.props.customData.forceFilterSearch)) {
        if(this.props.customData.customRequest && this.props.customData.forceFilterSearch) {
          this.getAll(this.props.customData.customRequest, this.props.customData.forceFilterSearch);
        } else if (this.props.customData.customRequest) {
          this.getAll(this.props.customData.customRequest);
        } else {
          this.getAll(undefined, this.props.customData.forceFilterSearch);
        }
      } else {
        this.getAll();
      }
    }

    let filteredItems: TDto[] = this.state.pagedAllItems ? this.state.pagedAllItems : [];
    let tableColumns = this.getColumns();
    let defaultColumns: IColumn[] = this.mapToColumn(tableColumns);

    let { columns, items } = this.orderBy(filteredItems, defaultColumns);
    const values: any = this.tranformValues(items);
    let pageInfo: string = this.props.store.dataSet ? 
                (this.state.asyncActionInProgress ? ` [- / -]` : 
                  ` [${values.length} / ${
                    (this.props.customData && this.props.customData.disableShimmer === true && values.length === 0) || (this._getAllProceded && values.length === 0) ?
                      '0' : this.props.store.dataSet.totalCount}]`) : "";

    return this.renderAll(pageInfo, values, columns);
  }

  renderAll(pageInfo: string, values: any, columns: any) {
    return <>
      <ThemeProvider theme={myTheme}>
        {this.renderCommandBarBase()}

        {this.renderAnnounced(pageInfo)}

        {this.renderListScrollablePane(values, columns)}
      </ThemeProvider>

      {this.renderDialog()}

      {this.renderPanel()}
    </>;
  }

  renderListScrollablePane(values: any, columns: any) {
    return <ScrollablePane style={{ marginTop: typeof this.props.scrollablePanelMarginTop === 'number' ? this.props.scrollablePanelMarginTop : 180 }} 
        scrollbarVisibility={ScrollbarVisibility.auto} theme={myTheme} className={classNames.customScrollablePane}
      >
        <MarqueeSelection selection={this._selection} isEnabled={false}>
          {this.renderList(values, this.state.isCompactMode, columns, this.state.isShimmerEnabled, classNames, this._selection, this.onItemInvoked)}
        </MarqueeSelection>

        <div className={classNames.paginationFooter} ref={this._footerRef} />
      </ScrollablePane>;
  }

  renderAnnounced(pageInfo: string) {
    return <Sticky stickyPosition={StickyPositionType.Header}>
      {this.props.history && this.props.history.location && !!this.props.history.location.pathname &&
        <div  id={`${this.props.history.location.pathname.replaceAll('/', '')}-additional-scrollbar`} style={{width: '100%', height: '17px', overflowX: 'scroll', display:'none'}}>
          <div  style={{width: `${document.querySelector('.ms-DetailsList-contentWrapper')?.querySelector('.ms-SelectionZone')?.querySelector('.ms-FocusZone')?.getBoundingClientRect().width}px`,
                height: '17px'}}>
          </div>
        </div>
      }

      <Announced className={classNames.selectionDetails} message={this.state.selectionDetails + pageInfo} />

      <div style={{width: '100%', height: '0px', position: 'relative', marginTop: '20px'}}>
        {this.state.asyncActionInProgress && (
            <Spinner label={''} className={`${classNames.smallLoadSpinner} ${classNames.bottomPositionedLoadSpinner}`}
              size={SpinnerSize.medium} ariaLive="assertive" labelPosition="bottom" />
        )}
      </div>
    </Sticky>;
  }

  renderDialog() {
    return <Dialog
      hidden={!this.state.showDialog}
      onDismiss={this._closeDialog}
      dialogContentProps={{
        type: DialogType.normal,
        title: this.state.dialogTitle,
        closeButtonAriaLabel: L('Close'),
      }}
      modalProps={{
        titleAriaId: this._labelId,
        subtitleAriaId: this._subTextId,
        isBlocking: true,
        styles: { main: { maxWidth: 450 } },
      }}
      theme={myTheme}
    >
      <DialogFooter theme={myTheme}>
        <DefaultButton theme={myTheme} onClick={this._closeDialog} text={L('No')} />
        <PrimaryButton
          onClick={async () => await this.onDialogYesClick()}
          text={L('Yes')}
          theme={myTheme}
          disabled={this.state.dialogYesButtonDisabled}
        />
      </DialogFooter>
    </Dialog>;
  }

  renderList(values: any[], isCompactMode: boolean, columns: IColumn[], isDataLoaded: boolean, classNames: any, selection: any, onItemInvoked: any) {
    return <ShimmeredDetailsList
      items={values}
      compact={isCompactMode}
      columns={columns}
      onRenderDetailsHeader={this.onRenderDetailsHeader}
      selection={selection}
      selectionMode={selection.mode}
      enableShimmer={typeof isDataLoaded === 'undefined' ? false : isDataLoaded}
      setKey="none"
      layoutMode={DetailsListLayoutMode.justified}
      isHeaderVisible={true}
      checkButtonAriaLabel="Row checkbox"
      onItemInvoked={onItemInvoked}
      className={classNames.tableWrapper}
      theme={myTheme}
      checkboxCellClassName={classNames.checkboxCell}
      onRenderRow={this._onRenderRow}
    />;
  }

  renderCommandBar(props: ICommandBarBaseProps<TDto>): JSX.Element {
    return <CommandBarBase< TDto > {...props} />;
  }

  renderCommandBarBase(): JSX.Element {
    return <Sticky stickyPosition={StickyPositionType.Header}>
      {this.renderCommandBar({...this.getCommandBarBaseProps()})}
    </Sticky>;
  }

  renderTitle(): JSX.Element {
    return (
      <div className={classNames.headerWrapper}>
        <h2>{this.getTitle()}</h2>
      </div>
    );
  }

  renderBulkPanelView(props: IGenericPanelProps): JSX.Element {
    return this.renderPanelView(props);
  }

  async setFiltersItemsOptions(props: any, showFiltersDialogProp?: boolean, toggleColumnFilterDialogForIds?: any) {
    let stateSet: boolean = false;

    if((!this.state.currentFiltersOptions || Object.keys(this.state.currentFiltersOptions).length === 0) && this.state.currentFiltersSearchValues?.length === 0) {
      await this.props.store.getColumnValue([]).then((getColumnValueResponse: any) => {
        stateSet = true;

        if(getColumnValueResponse) {
          this.setState({ 
            currentFiltersOptions: this.parseFiltersItemsOptions(getColumnValueResponse),
            showFiltersDialog: typeof showFiltersDialogProp === 'boolean' ? showFiltersDialogProp : this.state.showFiltersDialog, 
            toggleColumnFilterDialogForIds: toggleColumnFilterDialogForIds && typeof toggleColumnFilterDialogForIds === 'object' ? toggleColumnFilterDialogForIds : this.state.toggleColumnFilterDialogForIds 
          });
        } else {
          this.setState({asyncActionInProgress: false, showFiltersDialog: typeof showFiltersDialogProp === 'boolean' ? showFiltersDialogProp : this.state.showFiltersDialog});
        }
      }).catch((error: any) => {
        console.error(error);
      });
    }

    if(!stateSet) {
      this.setState({ 
        showFiltersDialog: typeof showFiltersDialogProp === 'boolean' ? showFiltersDialogProp : this.state.showFiltersDialog,
        toggleColumnFilterDialogForIds: toggleColumnFilterDialogForIds && typeof toggleColumnFilterDialogForIds === 'object' ? toggleColumnFilterDialogForIds : this.state.toggleColumnFilterDialogForIds
      });
    }
  }

  parseFiltersItemsOptions(getColumnValueResponse: any, refreshKey?: string[]): any {
    const optionLabelsToTranslate: string[] = ['TRUE', 'FALSE'];
    const tempCurrentFiltersOptions: any = {};
    
    getColumnValueResponse.forEach((option: any) => {
      const alreadyPushedKeys: string[] = [];
      const key: string = option.name;

      if(!tempCurrentFiltersOptions[key]) {
        tempCurrentFiltersOptions[key] = [] as IComboBoxOption[];
      }
      
      if(refreshKey && refreshKey.includes(key)) {
        tempCurrentFiltersOptions[key] = [] as IComboBoxOption[];

        if(this.state.currentFilters && this.state.currentFilters[key]) {
          let cloneCurrentFilters: string[] = this.state.currentFilters[key];

          cloneCurrentFilters.forEach((currentFilter: string) => {
            if(!!currentFilter && !alreadyPushedKeys.includes(currentFilter)) {
              alreadyPushedKeys.push(currentFilter);

              let id: string = currentFilter.toLowerCase().split(' ').join("_");

              tempCurrentFiltersOptions[key].push({
                key: currentFilter, id: id, text: moment(currentFilter).isValid() ? dateFormat(currentFilter) : currentFilter, 
                data: {fieldName: key}, selected: true,
              } as IComboBoxOption);
            }
          });
        }
      }

      if(!!option.value && isJsonString(option.value) && Array.isArray(JSON.parse(option.value))) {
        JSON.parse(option.value).forEach((optionValue: string) => {
          if(!!optionValue && !alreadyPushedKeys.includes(optionValue)) {
            alreadyPushedKeys.push(optionValue);
  
            let id: string = optionValue.toLowerCase().split(' ').join("_");
  
            tempCurrentFiltersOptions[key].push({
              key: optionValue, id: id, data: {fieldName: key},
              text: moment(optionValue).isValid() ? dateFormat(optionValue) : (optionLabelsToTranslate.includes(optionValue) ? L(optionValue) : optionValue), 
              selected: this.state.currentFilters && this.state.currentFilters[key] && this.state.currentFilters[key].includes(optionValue) ? true : false,
            } as IComboBoxOption);
          }
        });
      }
    });

    return tempCurrentFiltersOptions;
  }

  private clearAllFilters(dissmissColumnFilterDialogColId?: string) {
    const cloneCurrentFiltersOptions: any = {...this.state.currentFiltersOptions};

    for(let fieldName in cloneCurrentFiltersOptions) {
      if(cloneCurrentFiltersOptions.hasOwnProperty(fieldName)) {
        cloneCurrentFiltersOptions[fieldName].forEach((option: any) => {
          option.selected = false;
        });
      }
    }

    if(!!dissmissColumnFilterDialogColId) {
      this.setState({
        currentFilters: {}, 
        currentFiltersOptions: cloneCurrentFiltersOptions, 
        toggleColumnFilterDialogForIds: this.dissmissColumnFilterDialog(dissmissColumnFilterDialogColId, undefined, true)
      }, () => {
        this.getAll();
      });
    } else {
      this.setState({currentFilters: {}, currentFiltersOptions: cloneCurrentFiltersOptions}, () => {
        this.getAll();
      });
    }
  }

  renderDetailedFiltersSection(props: any): JSX.Element {
    return <>
      <Dialog
        hidden={!this.state.showFiltersDialog}
        onDismiss={this._closeFiltersDialog}
        dialogContentProps={{
          type: DialogType.close,
          closeButtonAriaLabel: L('Close'),
        }}
        modalProps={{
          isBlocking: true,
          styles: { main: { maxWidth: '83vw !important' } },
          className: classNames.dialogContent,
        }}
        theme={myTheme}
      >
        {this.state.asyncActionInProgress && (
          <Spinner label={''} className={`${classNames.smallLoadSpinner} ${classNames.bottomPositionedLoadSpinner}`}
            size={SpinnerSize.medium} ariaLive="assertive" labelPosition="bottom" 
            style={{position: 'absolute', top: '-41px', left: 0, height: '20px', ...(this.props.customData && !!this.props.customData.customLoadSpinnerStyle ? this.props.customData.customLoadSpinnerStyle : {})}} />
        )}

        <DialogContent styles={{
          content: {width: '80vw'},
          subText: {},
          header: {display: 'none'},
          button: {},
          inner: {width: '80vw', padding: '0'},
          innerContent: {width: '80vw', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'flex-start'},
          title: {},
          topButton: {},
        }}>
          {props.columns.filter((column: any) => {
            if(!this.props.hideColumnsInFilters && !this.props.hideColumnsInFiltersByName) {
              return true;
            } else if((this.props.hideColumnsInFilters && this.props.hideColumnsInFilters.includes(column.fieldName)) || 
                      (this.props.hideColumnsInFiltersByName && this.props.hideColumnsInFiltersByName.includes(column.name))
            ) {
              return false;
            } else {
              return true;
            }
          }).map((column: ITableColumn) => {
            return <div key={column.name} style={{
                display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', alignItems: 'center', justifyContent: 'center', marginBottom: '15px', 
                border: this.state.currentFilters && this.state.currentFilters[column.fieldName] && this.state.currentFilters[column.fieldName].length > 0 ? `1px solid ${myTheme.palette.themeLighter}` : `1px solid ${myTheme.palette.neutralLighter}`, 
                background: this.state.currentFilters && this.state.currentFilters[column.fieldName] && this.state.currentFilters[column.fieldName].length > 0 ? myTheme.palette.themeLighter : myTheme.palette.neutralLighter, 
                borderRadius: '15px', padding: '10px 7px', width: '31%',
              }}
            >
              <LabeledTextField label={column.name} isDataLoaded={true} labelContainerCustomStyles={{marginTop: 5, marginBottom: 8}}
                customLabelStyles={{minWidth: '100px', width: 'auto', marginRight: '10px', textAlign: 'right'}}
                customWidth={200} disabled={this.state.asyncActionInProgress}
                iconName='Search'
                iconProps={{title: L('Search')}}
                iconOnClick={async (iconIndex?: number) => {
                  const stringifyCurrentFiltersSearchValues: string = JSON.stringify(this.state.currentFiltersSearchValues);
                  if(this.state.currentFiltersSearchValues && (this.state.currentFiltersSearchValues?.length > 0 || stringifyCurrentFiltersSearchValues !== this.state.prevFiltersSearchValues)) {
                    this.setState({asyncActionInProgress: true, currentFiltersOptions: {}, prevFiltersSearchValues: stringifyCurrentFiltersSearchValues});

                    let keysToRefresh: string[] = [];
                    this.state.currentFiltersSearchValues.forEach((filter: any) => {
                      if(filter && filter.name && !!filter.name) {
                        keysToRefresh.push(filter.name);
                      }
                    });
  
                    await this.props.store.getColumnValue(this.state.currentFiltersSearchValues).then((getColumnValueResponse: any) => {
                      if(getColumnValueResponse) {
                        this.setState({currentFiltersOptions: this.parseFiltersItemsOptions(getColumnValueResponse, keysToRefresh), asyncActionInProgress: false});
                      } else {
                        this.setState({asyncActionInProgress: false});
                      }
                    }).catch((error: any) => {
                      console.error(error);
                      this.setState({asyncActionInProgress: false});
                    });
                  }
                }}
                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
                  const cloneFiltersSearchValues: any = this.state.currentFiltersSearchValues ? this.state.currentFiltersSearchValues : [];
                  let foundExistingFilterSearchValueIndex: number = -1;

                  cloneFiltersSearchValues.some((element: any, elementIndex: number) => {
                    if(element.name === column.fieldName) {
                      foundExistingFilterSearchValueIndex = elementIndex;
                      return true;
                    }
                    return false;
                  });

                  if(!!newValue) { 
                    if(foundExistingFilterSearchValueIndex >= 0) {
                      cloneFiltersSearchValues[foundExistingFilterSearchValueIndex] = {name: column.fieldName, value: newValue};
                    } else {
                      cloneFiltersSearchValues.push({name: column.fieldName, value: newValue});
                    }
                  } else if(foundExistingFilterSearchValueIndex >= 0) {
                    cloneFiltersSearchValues.splice(foundExistingFilterSearchValueIndex, 1);
                  }

                  this.setState({currentFiltersSearchValues: cloneFiltersSearchValues});
                }} 
              /> 

              {(this.state.currentFiltersOptions && Object.keys(this.state.currentFiltersOptions).length > 0) ?
                <ComboBox
                  multiSelect
                  defaultSelectedKey={this.state.currentFilters ? this.state.currentFilters : []}
                  label=""
                  allowFreeform={false}
                  disabled={this.state.asyncActionInProgress}
                  options={this.state.currentFiltersOptions ? this.state.currentFiltersOptions[column.fieldName] : []}
                  onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) => {
                    if(!option || !option.data || !option.data.fieldName) {
                      return;
                    }

                    const cloneCurrentFilters: any = {...this.state.currentFilters};
                    const cloneCurrentFiltersOptions: any = {...this.state.currentFiltersOptions};

                    if(option.selected === true && !cloneCurrentFilters[option.data.fieldName]) {
                      cloneCurrentFilters[option.data.fieldName] = [option.key];
                    } else if(option.selected === true && !cloneCurrentFilters[option.data.fieldName].includes(option.key)) {
                      cloneCurrentFilters[option.data.fieldName].push(option.key);
                    } else if(option.selected !== true && (cloneCurrentFilters[option.data.fieldName] && cloneCurrentFilters[option.data.fieldName].includes(option.key))) {
                      cloneCurrentFilters[option.data.fieldName] = cloneCurrentFilters[option.data.fieldName].filter((element: string) => {
                        return element !== option.key;
                      });

                      if(cloneCurrentFilters[option.data.fieldName].length === 0) {
                        delete cloneCurrentFilters[option.data.fieldName];
                      }
                    }

                    cloneCurrentFiltersOptions[option.data.fieldName].some((stateOption: any, stateOptionIndex: number) => {
                      if(stateOption.id === option.id) {
                        cloneCurrentFiltersOptions[option.data.fieldName][stateOptionIndex] = option;
                        return true;
                      }
                      return false;
                    });
                    
                    this.setState({currentFilters: cloneCurrentFilters, currentFiltersOptions: cloneCurrentFiltersOptions});
                  }}
                  style={{width: '90%', marginBottom: '5px'}}
                />
                :
                <ComboBox
                  key={Math.random()}
                  label=""
                  allowFreeform={false}
                  disabled={true}
                  options={[]}
                  style={{width: '90%', marginBottom: '5px'}}
                />
              }
            </div>;
          })}
        </DialogContent>

        <DialogFooter theme={myTheme}>
          <DefaultButton
            onClick={() => this.clearAllFilters()}
            text={L('Remove all filters')}
            theme={myTheme}
            disabled={!this.state.currentFilters}
          />

          <PrimaryButton
            onClick={async () => this.getAll()}
            text={L('Search')}
            theme={myTheme}
            disabled={!this.state.currentFilters || Object.keys(this.state.currentFilters).length === 0}
          />
        </DialogFooter>
      </Dialog>

      <Icon iconName='FilterSolid' title={L('Filters')} onClick={() => { this._showFiltersDialog(props); }} 
        style={{ cursor: 'pointer', fontSize: '20px', display: 'block', textAlign: 'right', marginBottom: '5px', paddingRight: '15px' }} 
      />
    </>;
  }

  dissmissColumnFilterDialog = (columnId: string, getAllInCallback?: boolean, onlyReturnObjectToSet?: boolean): any | void => {
    const cloneToggleColumnFilterDialogForIds: any =  this.state.toggleColumnFilterDialogForIds ? {...this.state.toggleColumnFilterDialogForIds} : {};
    cloneToggleColumnFilterDialogForIds[columnId] = false;

    if(onlyReturnObjectToSet === true) {
      return cloneToggleColumnFilterDialogForIds;
    }

    if(getAllInCallback === true) {
      this.setState({ toggleColumnFilterDialogForIds: cloneToggleColumnFilterDialogForIds }, () => {
        this.getAll();
      });
    } else {
      this.setState({ toggleColumnFilterDialogForIds: cloneToggleColumnFilterDialogForIds });
    }
  }

  onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props) {
      return null;
    }
    
    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = (tooltipHostProps) => {
      const column: IColumn | undefined = tooltipHostProps && tooltipHostProps.column ? tooltipHostProps.column : undefined;
      const colId: string = (column && !!column.fieldName) ? `columnFilterIcon${column.fieldName}` : '';

      const isValueADate: boolean = column && !!column.fieldName && this.state.currentFiltersOptions && this.state.currentFiltersOptions[column.fieldName] &&
        this.state.currentFiltersOptions[column.fieldName][0] && moment(this.state.currentFiltersOptions[column.fieldName][0].key).isValid() ? true : false;
      const RCTIconStyle: React.CSSProperties = {fontFamily: 'FabricMDL2Icons', fontStyle: 'normal', fontWeight: 'normal'};
      const checkboxTreeNodes: any[] = [];

      if(isValueADate === true) {
        const yearChildren: any = {};
        const monthChildren: any = {};

        this.state.currentFiltersOptions![column!.fieldName!].filter((option: any) => !!option.key && moment(option.key).isValid()).forEach((option: any) => {
          const date = moment(option.key);
          const year = date.year();
          const month = (date.month() + 1) < 10 ? '0' + (date.month() + 1) : date.month() + 1;
          const day = date.date() < 10 ? '0' + date.date() : date.date();

          let currentYearIndex: number | undefined = undefined;
          let currentMonthIndex: number | undefined = undefined;
          let alreadyHasDay: boolean = false;

          checkboxTreeNodes.some((optionYear: any, yearIndex: number) => {
            if(optionYear && optionYear.value === year) {
              currentYearIndex = yearIndex;

              optionYear.children.some((optionMonth: any, monthIndex: number) => {
                if(optionMonth && optionMonth.value === `${month}-${year}`) {
                  currentMonthIndex = monthIndex;
    
                  optionMonth.children.some((optionDay: any) => {
                    if(optionDay && optionDay.value === `${day}-${month}-${year}`) {
                      alreadyHasDay = true;
                      return true;
                    }
                    return false;
                  });
    
                  return true;
                }
                return false;
              });

              return true;
            }
            return false;
          });

          if(alreadyHasDay === false) {
            if(!monthChildren[year]) {
              monthChildren[year] = {};
            }

            if(!monthChildren[year][month]) {
              monthChildren[year][month] = [];
            }

            monthChildren[year][month].push({
              value: `${day}-${month}-${year}`,
              label: `${day}.${month}.${year}`,
            });
          }

          if(typeof currentMonthIndex === 'undefined') {
            if(!yearChildren[year]) {
              yearChildren[year] = [];
            }

            yearChildren[year].push({
              value: `${month}-${year}`,
              label: L(date.format('MMMM')),
              children: monthChildren[year][month],
            });
          }

          if(typeof currentYearIndex === 'undefined') {
            checkboxTreeNodes.push({
              value: year,
              label: year,
              children: yearChildren[year],
            });
          }
        });
      }

      return this.props.showFilters === true && !this.props.showFiltersInSingleDialog && column ?  
        <>
          {(column && !!column.fieldName && 
            ((!this.props.hideColumnsInFilters || !this.props.hideColumnsInFilters.includes(column.fieldName)) && (!this.props.hideColumnsInFiltersByName || !this.props.hideColumnsInFiltersByName.includes(column.name)))
          ) &&
            <>
              {(this.state.toggleColumnFilterDialogForIds && this.state.toggleColumnFilterDialogForIds[colId] && this.state.toggleColumnFilterDialogForIds[colId] === true) &&
                <FocusTrapCallout
                  role="columnFilterDialog"
                  className={classNames.columnFilterCallout}
                  gapSpace={0}
                  target={`#${colId}`}
                  onDismiss={() => this.dissmissColumnFilterDialog(colId)}
                  //setInitialFocus
                >
                  <Stack>
                    <LabeledTextField label={column.name} isDataLoaded={true} labelContainerCustomStyles={{marginTop: 5, marginBottom: 8}}
                      customLabelStyles={{minWidth: '100px', width: 'auto', marginRight: '10px', textAlign: 'right'}}
                      customWidth={200} disabled={this.state.asyncActionInProgress}
                      iconName='Search'
                      iconProps={{title: L('Search')}}
                      iconOnClick={async (iconIndex?: number) => {
                        const stringifyCurrentFiltersSearchValues: string = JSON.stringify(this.state.currentFiltersSearchValues);
                        if(this.state.currentFiltersSearchValues && (this.state.currentFiltersSearchValues?.length > 0 || stringifyCurrentFiltersSearchValues !== this.state.prevFiltersSearchValues)) {
                          this.setState({asyncActionInProgress: true, currentFiltersOptions: {}, prevFiltersSearchValues: stringifyCurrentFiltersSearchValues});

                          let keysToRefresh: string[] = [];
                          this.state.currentFiltersSearchValues.forEach((filter: any) => {
                            if(filter && filter.name && !!filter.name) {
                              keysToRefresh.push(filter.name);
                            }
                          });
        
                          await this.props.store.getColumnValue(this.state.currentFiltersSearchValues).then((getColumnValueResponse: any) => {
                            if(getColumnValueResponse) {
                              this.setState({currentFiltersOptions: this.parseFiltersItemsOptions(getColumnValueResponse, keysToRefresh), asyncActionInProgress: false});
                            } else {
                              this.setState({asyncActionInProgress: false});
                            }
                          }).catch((error: any) => {
                            console.error(error);
                            this.setState({asyncActionInProgress: false});
                          });
                        }
                      }}
                      onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
                        const cloneFiltersSearchValues: any = this.state.currentFiltersSearchValues ? this.state.currentFiltersSearchValues : [];
                        let foundExistingFilterSearchValueIndex: number = -1;

                        cloneFiltersSearchValues.some((element: any, elementIndex: number) => {
                          if(element.name === column.fieldName) {
                            foundExistingFilterSearchValueIndex = elementIndex;
                            return true;
                          }
                          return false;
                        });

                        if(!!newValue) { 
                          if(foundExistingFilterSearchValueIndex >= 0) {
                            cloneFiltersSearchValues[foundExistingFilterSearchValueIndex] = {name: column.fieldName, value: newValue};
                          } else {
                            cloneFiltersSearchValues.push({name: column.fieldName, value: newValue});
                          }
                        } else if(foundExistingFilterSearchValueIndex >= 0) {
                          cloneFiltersSearchValues.splice(foundExistingFilterSearchValueIndex, 1);
                        }

                        this.setState({currentFiltersSearchValues: cloneFiltersSearchValues});
                      }} 
                    /> 

                    {(this.state.currentFiltersOptions && Object.keys(this.state.currentFiltersOptions).length > 0) ?
                      <>
                        {isValueADate === true && checkboxTreeNodes.length > 0 ? 
                          <CheckboxTree
                            nodes={checkboxTreeNodes}
                            checked={this.state.customData && this.state.customData.checkboxTreeChecked && this.state.customData.checkboxTreeChecked[column.fieldName] ? 
                              this.state.customData.checkboxTreeChecked[column.fieldName] : []}
                            expanded={this.state.customData && this.state.customData.checkboxTreeExpanded && this.state.customData.checkboxTreeExpanded[column.fieldName] ? 
                              this.state.customData.checkboxTreeExpanded[column.fieldName] : []}
                            onCheck={(checked) => {
                              let cloneCustomData = this.state.customData ? {...this.state.customData} : {};
                              if(cloneCustomData['checkboxTreeChecked']) {
                                cloneCustomData['checkboxTreeChecked'][column.fieldName!] = checked;
                              } else {
                                cloneCustomData['checkboxTreeChecked'] = {};
                                cloneCustomData['checkboxTreeChecked'][column.fieldName!] = checked;
                              }

                              const cloneCurrentFilters: any = {...this.state.currentFilters};

                              if(checked.length === 0 && (column && !!column.fieldName)) {
                                delete cloneCurrentFilters[column.fieldName];
                              } else if(column && !!column.fieldName) {
                                cloneCurrentFilters[column.fieldName] = checked;
                              }

                              this.setState({ currentFilters: cloneCurrentFilters, customData: cloneCustomData });
                            }}
                            onExpand={(expanded) => {
                              let cloneCustomData = this.state.customData ? {...this.state.customData} : {};
                              if(cloneCustomData['checkboxTreeExpanded']) {
                                cloneCustomData['checkboxTreeExpanded'][column.fieldName!] = expanded;
                              } else {
                                cloneCustomData['checkboxTreeExpanded'] = {};
                                cloneCustomData['checkboxTreeExpanded'][column.fieldName!] = expanded;
                              }

                              this.setState({ customData: cloneCustomData });
                            }}
                            nativeCheckboxes={true}
                            // noCascade={true}
                            icons={{
                              check: <Icon iconName='CheckboxFill' className="rct-icon@ rct-icon-check@" style={{...RCTIconStyle, fontFamily: 'FabricMDL2Icons-0'}} />,
                              uncheck: <Icon iconName='Checkbox' className="rct-icon@ rct-icon-uncheck@" style={{...RCTIconStyle, fontFamily: 'FabricMDL2Icons-0'}} />,
                              halfCheck: <Icon iconName='CheckboxComposite' className="rct-icon@ rct-icon-half-check@" style={{...RCTIconStyle, fontFamily: 'FabricMDL2Icons-0'}} />,
                              expandClose: <Icon iconName='ChevronRight' className="rct-icon@ rct-icon-expand-close@" style={RCTIconStyle} />,
                              expandOpen: <Icon iconName='ChevronDown' className="rct-icon@ rct-icon-expand-open@" style={RCTIconStyle} />,
                              expandAll: <Icon iconName='CirclePlus' className="rct-icon@ rct-icon-expand-all@" style={RCTIconStyle} />,
                              collapseAll: <Icon iconName='CalculatorSubtract' className="rct-icon@ rct-icon-collapse-all@" style={RCTIconStyle} />,
                              parentClose: <Icon iconName='FolderHorizontal' className="rct-icon@ rct-icon-parent-close@" style={{...RCTIconStyle, display: 'none'}} />,
                              parentOpen: <Icon iconName='OpenFolderHorizontal' className="rct-icon@ rct-icon-parent-open@" style={{...RCTIconStyle, display: 'none'}} />,
                              leaf: <Icon iconName='Page' className="rct-icon@ rct-icon-leaf@" style={{...RCTIconStyle, display: 'none'}} />,
                            }}
                          />
                          :
                          <ComboBox
                            multiSelect
                            defaultSelectedKey={this.state.currentFilters ? this.state.currentFilters : []}
                            label=""
                            allowFreeform={false}
                            disabled={this.state.asyncActionInProgress}
                            options={this.state.currentFiltersOptions ? this.state.currentFiltersOptions[column.fieldName] : []}
                            onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined) => {
                              if(!option || !option.data || !option.data.fieldName) {
                                return;
                              }

                              const cloneCurrentFilters: any = {...this.state.currentFilters};
                              const cloneCurrentFiltersOptions: any = {...this.state.currentFiltersOptions};

                              if(option.selected === true && !cloneCurrentFilters[option.data.fieldName]) {
                                cloneCurrentFilters[option.data.fieldName] = [option.key];
                              } else if(option.selected === true && !cloneCurrentFilters[option.data.fieldName].includes(option.key)) {
                                cloneCurrentFilters[option.data.fieldName].push(option.key);
                              } else if(option.selected !== true && (cloneCurrentFilters[option.data.fieldName] && cloneCurrentFilters[option.data.fieldName].includes(option.key))) {
                                cloneCurrentFilters[option.data.fieldName] = cloneCurrentFilters[option.data.fieldName].filter((element: string) => {
                                  return element !== option.key;
                                });

                                if(cloneCurrentFilters[option.data.fieldName].length === 0) {
                                  delete cloneCurrentFilters[option.data.fieldName];
                                }
                              }

                              cloneCurrentFiltersOptions[option.data.fieldName].some((stateOption: any, stateOptionIndex: number) => {
                                if(stateOption.id === option.id) {
                                  cloneCurrentFiltersOptions[option.data.fieldName][stateOptionIndex] = option;
                                  return true;
                                }
                                return false;
                              });

                              this.setState({currentFilters: cloneCurrentFilters, currentFiltersOptions: cloneCurrentFiltersOptions});
                            }}
                            style={{width: '90%', marginBottom: '5px'}}
                          />
                        }
                      </>
                      :
                      <ComboBox
                        key={Math.random()}
                        label=""
                        allowFreeform={false}
                        disabled={true}
                        options={[]}
                        style={{width: '90%', marginBottom: '5px'}}
                      />
                    }
                  </Stack>

                  <Stack className={classNames.columnFilterCalloutButtonsWrapper} gap={8} horizontal>
                    <PrimaryButton
                      onClick={async () => this.dissmissColumnFilterDialog(colId, true)}
                      text={L('Search')}
                      theme={myTheme}
                      disabled={!this.state.currentFilters || Object.keys(this.state.currentFilters).length === 0}
                    />

                    <DefaultButton
                      onClick={() => this.clearAllFilters(colId)}
                      text={L('Remove all filters')}
                      theme={myTheme}
                      disabled={!this.state.currentFilters}
                      style={{background: myTheme.palette.red, color: myTheme.palette.white}}
                    />

                    <DefaultButton onClick={() => this.dissmissColumnFilterDialog(colId) }>
                      {L('Cancel')}
                    </DefaultButton>
                  </Stack>
                </FocusTrapCallout>
              }
              
              <Icon iconName={this.state.currentFilters && this.state.currentFilters[column.fieldName] && this.state.currentFilters[column.fieldName].length > 0 ? 'FilterSolid' : 'Filter'} 
                title={L('Filter')} id={colId}
                style={{ cursor: 'pointer', fontSize: '15px', display: 'block', textAlign: 'right', position: 'absolute', right: 0, padding: '0 8px', zIndex: 10, background: myTheme.palette.neutralLighter,
                  borderRight: `1px solid ${myTheme.palette.neutralTertiaryAlt}`,
                  color: this.state.currentFilters && this.state.currentFilters[column.fieldName] && this.state.currentFilters[column.fieldName].length > 0 ? myTheme.palette.themePrimary : myTheme.palette.black,
                }} 
                onClick={() => { 
                  const cloneToggleColumnFilterDialogForIds: {} =  this.state.toggleColumnFilterDialogForIds ? {...this.state.toggleColumnFilterDialogForIds} : {};
                  cloneToggleColumnFilterDialogForIds[colId] = true;

                  this.setFiltersItemsOptions(props, false, cloneToggleColumnFilterDialogForIds);
                  // this.setState({ toggleColumnFilterDialogForIds: cloneToggleColumnFilterDialogForIds });
                }} 
              />
            </>
          }
          <TooltipHost theme={myTheme} {...tooltipHostProps} />
        </>
        :
        <TooltipHost theme={myTheme} {...tooltipHostProps} />
    };

    return (
      <Sticky stickyClassName={classNames.tableWrapper} stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
        {this.props.showFilters === true && this.props.showFiltersInSingleDialog === true && 
          this.renderDetailedFiltersSection(props)
        }

        {defaultRender!({
          ...props,
          onRenderColumnHeaderTooltip,
        })}
      </Sticky>
    );
  };

  private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      if (props.itemIndex % 2 === 0) {
        // Every other row renders with a different background color
        customStyles.root = { backgroundColor: additionalTheme.white };
      }
      return <DetailsRow className={classNames.rowStyle} {...props} styles={customStyles}  />;
    }
    return null;
  };

  renderPanel(): JSX.Element {
    const props: IGenericPanelProps = {
      isOpen: this.state.modalVisible,
      modalType: this.state.dialogEntityId === '' ? ModalTypes.Create : ModalTypes.Update,
      payload: this.getPayload(),
      isBulkOperation: this.state.isBulkOperation,
      customData: this.props.customData,
      createOrUpdate: async (values: any) => await this.createOrUpdate(values),
      onClose: (calledFromButton: boolean) => { this.closeModal(calledFromButton) },
    };

    if (this.state.isBulkOperation) {
      return this.renderBulkPanelView(props);
    } else {
      return this.renderPanelView(props);
    }
  }

  renderPanelView(props: IGenericPanelProps): JSX.Element {
    return <GenericPanel
      {...props}
    />
  }

  getColumns(): ITableColumn[] {
    return [];
  }

  getItemDisplayNameOf(item: TDto): string {
    let namedItem = item as any as NamedEntityStringDto;
    return namedItem && !!namedItem.name ? namedItem.name : "";
  }

  getTitle(): string {
    return ''
  }

  getItemFilterString(item: TDto): string {
    let array: string[] = [];

    this.getColumns().forEach((x) => {
      let property: string | null = null;
      let fieldName = x.fieldName;
      let splittedFieldName: string[] = fieldName.split('.');

      if(splittedFieldName.length > 1) {
        let deeperModelValue = [];
        deeperModelValue[0] = item;
        for(let i = 1; i <= splittedFieldName.length; i++) {
          if(deeperModelValue[i-1] && splittedFieldName[i-1] && deeperModelValue[i-1][splittedFieldName[i-1]]) {
            deeperModelValue[i] = deeperModelValue[i-1][splittedFieldName[i-1]];
          } else {
            break;
          }
        }

        property = typeof deeperModelValue[deeperModelValue.length - 1] === 'number' ? deeperModelValue[deeperModelValue.length - 1].toString() : deeperModelValue[deeperModelValue.length - 1];
      } else {
        property = typeof item[fieldName] === 'number' ? item[fieldName].toString() : item[fieldName];
      }

      if(!!property && property.length >= 10 && moment(property).isValid()) {
        array.push(dateFormat(property, "DD.MM.YYYY"));
      }

      if(!!property && property.length > 0) {
        array.push(property);
      }
    });

    return array.join();
  }

  getPayload(): any {
    // return this.props.store.model;
    return {model: this.props.store.model, entityId: this.state.dialogEntityId}
  }

  onFilter(allItems: TDto[], search: string): TDto[] {
    return allItems.filter((i) => this.getItemFilterString(i).toString().toLowerCase().indexOf(search.toLowerCase().trim()) > -1);
  }

  tranformValues(items: TDto[]): any[] {
    const array: any[] = [];
    items.forEach((item) => {
      if(!item['isHidden']) {
        array.push(this.transformToDisplayValues(item));
      }
    });
    return array;
  }

  transformToDisplayValues(row: TDto): any {
    return row;
  }

  private _getSelectionDetails(): string {
    if (this.hasAnyCrudPermission()) {
      const selectionCount = this._selection.getSelectedCount();
  
      switch (selectionCount) {
        case 0:
          return L('No items selected');
        case 1:
          return L('1 item selected') + ': ' + this.getItemDisplayNameOf(this._selection.getSelection()[0] as TDto);
        default:
          return `${selectionCount} ${L('items selected')}`;
      }
    } else {
      return '';
    }
  }

  getCommandBarBaseProps() {
    return {
      selection: this._selection,
      create: () => this.createOrUpdateModalOpen({ id: '' } as TDto),
      update: (item: TDto) => this.createOrUpdateModalOpen(item),
      delete: (item: TDto) => this.showDialog([item]),
      deleteMany: (items: TDto[]) => this.showDialog(items),
      updateMany: (items: TDto[]) => this.bulkOperation(items),
      permissons: this.getCrudPermission(),
      //more buttons
    };
  }

  getCrudPermission(): ICrudPermissons {
    return {
      create: true,
      update: true,
      delete: true,
      customActions: false,
    };
  }

  hasAnyCrudPermission(): boolean {
    let premissions = this.getCrudPermission();
    if (premissions.create || premissions.update || premissions.delete || premissions.customActions) {
      return true;
    } else {
      return false;
    }
  }

  private _showDialog = (): void => {
    this.setState({ showDialog: true });
  };

  private _closeDialog = (): void => {
    this.setState({ showDialog: false });
  };

  private _showFiltersDialog = (props?: any): void => {
    if(props) {
      this.setFiltersItemsOptions(props, true);
    } else {
      this.setState({ showFiltersDialog: true });
    }
  };

  private _closeFiltersDialog = (): void => {
    this.setState({ showFiltersDialog: false });
  };

  private async _closeDialogAsync(): Promise<boolean> {
    let closeDialogPromise = new Promise<boolean>(resolve => {
      this.setState({ showDialog: false }, () => {
        resolve(true);
      })
    });
    return await closeDialogPromise;
  }

  onItemInvoked = (item: any): void => {
    let premissions = this.getCrudPermission()
    if (premissions.update)
      this.createOrUpdateModalOpen({ id: item.id } as TDto);
  }

  orderBy(items: TDto[], columns: IColumn[]) {
    const { column } = this.state;
    if (!column) {
      return {
        columns,
        items
      }
    }

    const newColumns: IColumn[] = columns.slice();
    for (let i = 0; i < newColumns.length; i++) {
      if (newColumns[i].key === column.key) {
        newColumns[i].isSortedDescending = !column.isSortedDescending;
        newColumns[i].isSorted = true;
      } else {
        newColumns[i].isSorted = false;
        newColumns[i].isSortedDescending = true;
      }
    }
    
    const newItems = this.copyAndSort(items, column.fieldName!, column.isSortedDescending);

    return {
      columns: newColumns,
      items: newItems,
    }
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, newCol: IColumn): void => {
    const { column } = this.state;

    if(column && newCol.key === column.key) {
      newCol.isSortedDescending = typeof column.isSortedDescending === 'undefined' ? false : !column.isSortedDescending;
    } else if(typeof newCol.isSortedDescending === 'undefined') {
      newCol.isSortedDescending = false;
    } else {
      newCol.isSortedDescending = !newCol.isSortedDescending;
    }

    this.setState({
      column: newCol,
      currentOrderBy: newCol.fieldName,
      currentSorting: newCol.isSortedDescending === true ? 'desc' : 'asc'
    });
  };

  getSelection(): Selection {
    return this._selection;
  }

  private async _getAllInternal() {
    if (this.props.getAllAsync) {
      await this.props.getAllAsync();
    } else if (this.props.getAll) {
      this.props.getAll();
    } else {
      await this.getAll();
    }
  }
  
  copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    // this.setState({ skipCount: 0 });
    // this.getAll();
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
  }
}
