import './App.css';
import * as React from 'react';
import Router from './components/Router';
import SessionStore from './stores/sessionStore';
import SignalRAspNetCoreHelper from './lib/signalRAspNetCoreHelper';
import Stores from './stores/storeIdentifier';
import { inject } from 'mobx-react';
import { hasPermissionsToPage, isOnAllowedPath } from './utils/authUtils';
import './styles/main.css';
import AppConsts from './lib/appconst';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

export interface IAppProps {
  sessionStore?: SessionStore;
}

@inject(Stores.SessionStore)
class App extends React.Component<IAppProps> {
  async componentDidMount() {
    if(!isOnAllowedPath()) {
      // await this.props.sessionStore!.getCurrentLoginInformations();

      if (!!this.props.sessionStore!.currentLogin.user && this.props.sessionStore!.currentLogin.application.features['SignalR']) {
        if (this.props.sessionStore!.currentLogin.application.features['SignalR.AspNetCore']) {
          SignalRAspNetCoreHelper.initSignalR();
        }
      }
      
      if(!hasPermissionsToPage()) {
        window.location.href = '/exception';
      }
    }

    if(!!AppConsts.faviconPath) {
      const links: any = document.querySelectorAll("link[rel~='icon']");
      const faviconUrls: string[] = AppConsts.faviconPath.split(';;');

      if (!links || links.length === 0) {
        let link = document.createElement('link');
        link.rel = 'icon';
        link.href = faviconUrls[0];
        document.getElementsByTagName('head')[0].appendChild(link);
      } else {
        links.forEach((link: any, linkIndex: number) => {
          link.href = !!faviconUrls[linkIndex] ? faviconUrls[linkIndex] : faviconUrls[0];
        });
      }
    }
  }

  public render() {
    return <Router />;
  }
}

export default App;
