import { CrudStoreBase } from './crudStoreBase';
import { InsurancePolicyDto } from '../services/insurancePolicy/insurancePolicyDto';
import InsurancePolicyService from '../services/insurancePolicy/insurancePolicyService';

class InsurancePolicyStore extends CrudStoreBase<InsurancePolicyDto>{
	constructor() {
		super(InsurancePolicyService, defaultInsurancePolicy)
	}

	public async getPolicyByOrderId(orderId: string) {
        return InsurancePolicyService.getPolicyByOrderId(orderId);
    }
}

export const defaultInsurancePolicy = {
	id: '',
	comment: '',
	conclusionDate: '',
	creationTime: '',
	creatorUserId: 0,
	customerEmail: '',
	customerName: '',
	customerSurname: '',
	endDate: '',
	frequency: 0,
	insurer: '',
	lastModificationTime: '',
	lastModifierUserId: 0,
	offerNumber: 0,
	orderDate: '',
	orderGuid: '',
	orderItemGuid: '',
	orderNumber: 0,
	paymentMethod: '',
	paymentStatus: '',
	policyDate: '',
	policyNumber: '',
	policyTotal: 0,
	productId: '',
	reason: '',
	segment: '',
	startDate: '',
	status: '',
	fileUrl: '',
	customerId: '',
	clientId: 0,
	cancellationDate: '',
	payload: '',
	calculationId: 0,
	installments: [{}],
	details: ''
}

export default InsurancePolicyStore;