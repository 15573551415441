import { L } from '../../lib/abpUtility';
import AppConsts from '../../lib/appconst';
import { isConfigForAG, isConfigForProduction } from '../../utils/authUtils';
import LoadableComponent from './../Loadable/index';

export const RouterPath = {
  Apk: 'apk',
  ApkForm: 'apk-form',
  ApkList: 'apk-list',
  ApkFileSendPasswordBySms: 'apk-file/SendPasswordBySms',
  Events: 'events',
  EventMessages: 'event-messages',
  Squads: 'squads',
  Messages: 'messages',
  WelcomeMessages: 'greetings-messages',
  HealthStatus: 'health-status',
  About: 'about',
  Calculation: 'calculation',
  CalculationList: 'calculation-list',
  CustomerList: 'customer-list',
  Customer: 'customer',
  Contest: 'contest',
  ContestList: 'contest-list',
  ProductList: 'product-list',
  Product: 'product',
  PolicyCalculation: 'policy-calculation',
  PolicyCalculationApkFileSendPasswordBySms: 'policy-calculation-apk/SendPasswordBySms',
  PolicySendPasswordBySms: 'policy/SendPasswordBySms',
  PolicyForm: 'policy-form',
  ProductTypeList: 'product-type-list',
  ProductType: 'product-type',
  OrderList: 'order-list',
  Order: 'order',
  InsuranceCompanyList: 'insurance-company-list',
  InsuranceCompany: 'insurance-company',
  InsurancePolicyList: 'insurance-policy-list',
  InsurancePolicy: 'insurance-policy',
  UserList: 'user-list',
  RegisteredUser: 'registered-user',
  RoleList: 'role-list',
  Role: 'role',
  Profile: 'profile',
  ClaimList: 'claim-list',
  Claim: 'claim',
  AgentClaim: 'agent-claim',
  AgentClaimList: 'agent-claim-list',
  LoyaltyPointsHistoryList: 'loyalty-points-history-list',
  LoyaltyPointsHistory: 'loyalty-points-history',
  OcTermination: 'oc-termination',
  OcTerminationList: 'oc-termination-list',
  PatternList: 'pattern-list',
  Pattern: 'pattern',
  RatingList: 'rating-list',
  Rating: 'rating',
  Community: 'community',
  CommunityList: 'community-list',
  CustomerGroupList: 'customer-group-list',
  CustomerGroup: 'customer-group',
  TestSetList: 'test-set-list',
  TestSet: 'test-set',
  VehicleList: 'vehicle-list',
  Vehicle: 'vehicle',
  Settings: 'settings',
}

export const userRouter: any = [
  {
    path: '/user',
    name: 'user',
    title: 'User',
    component: LoadableComponent(() => import('../../components/Layout/UserLayout')),
    isLayout: true,
    showInMenu: false,
  },
  {
    path: '/Account/privacy-policy',
    name: 'privacy-policy',
    title: 'Privacy Policy',
    component: LoadableComponent(() => import('../../scenes/Account/PrivacyPolicy')),
    showInMenu: false,
  },
  {
    path: '/account/terms-of-use',
    name: 'terms-of-use',
    title: 'Terms of Use',
    component: LoadableComponent(() => import('../../scenes/Account/TermsOfUse')),
    showInMenu: false,
  },
  {
    path: '/user/login',
    name: 'login',
    title: 'LogIn',
    component: LoadableComponent(() => import('../../scenes/Login')),
    showInMenu: false,
  },
  {
    path: '/account/ConfirmEmail',
    name: 'ConfirmEmail',
    permission: '',
    title: 'ConfirmEmail',
    component: LoadableComponent(() => import('../../scenes/Account/ConfirmEmail')),
    showInMenu: false,
  },
  {
    path: '/account/ResetPassword',
    name: 'ResetPassword',
    permission: '',
    title: 'ResetPassword',
    component: LoadableComponent(() => import('../../scenes/Account/ResetPassword')),
    showInMenu: false,
  },
  {
    path: `/${RouterPath.ApkFileSendPasswordBySms}/:uuid`,
    name: `${RouterPath.ApkFileSendPasswordBySms}`,
    permission: '',
    title: L('Send password by SMS'),
    component: LoadableComponent(() => import('../../scenes/SendPasswordBySms')),
    showInMenu: false,
  },
  {
    path: `/${RouterPath.PolicyCalculationApkFileSendPasswordBySms}/:uuid`,
    name: `${RouterPath.PolicyCalculationApkFileSendPasswordBySms}`,
    permission: '',
    title: L('Send password by SMS'),
    component: LoadableComponent(() => import('../../scenes/SendPasswordBySms')),
    showInMenu: false,
  },
  {
    path: `/${RouterPath.PolicySendPasswordBySms}/:uuid`,
    name: `${RouterPath.PolicySendPasswordBySms}`,
    permission: '',
    title: L('Send password by SMS'),
    component: LoadableComponent(() => import('../../scenes/SendPasswordBySms')),
    showInMenu: false,
  },
];

export const appRouters: any = [
  {
    path: '/',
    exact: true,
    name: 'home',
    permission: '',
    title: L('Home'),
    icon: '',
    component: LoadableComponent(() => import('../../components/Layout/AppLayout')),
    isLayout: true,
    showInMenu: false,
  },
  {
    path: `/${RouterPath.CustomerList}`,
    title: L('Customers'),
    name: `${RouterPath.CustomerList}`,
    icon: '',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/CustomerList')),
  },
  {
    path: `/${RouterPath.Customer}/:id`,
    title: L('Customer'),
    name: `${RouterPath.Customer}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Customer')),
  },
  {
    path: `/${RouterPath.CalculationList}`,
    title: L('Calculations'),
    name: `${RouterPath.CalculationList}`,
    icon: '',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/CalculationList')),
  },
  {
    path: `/${RouterPath.Calculation}/:id`,
    title: L('Calculation'),
    name: `${RouterPath.Calculation}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Calculation')),
  },
  {
    path: `/${RouterPath.ContestList}`,
    title: L('Contests'),
    name: `${RouterPath.ContestList}`,
    icon: '',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/ContestList')),
  },
  {
    path: `/${RouterPath.Contest}/:id`,
    title: L('Contest'),
    name: `${RouterPath.Contest}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Contest')),
  },
  {
    path: `/${RouterPath.CustomerGroupList}`,
    title: L('Customer group list'),
    name: `${RouterPath.CustomerGroupList}`,
    icon: '',
    showInMenu: AppConsts.allowedContent === 'ALL' ? true : false,
    component: LoadableComponent(() => import('../../scenes/CustomerGroupList')),
  },
  {
    path: `/${RouterPath.CustomerGroup}/:id`,
    title: L('Customer group'),
    name: `${RouterPath.CustomerGroup}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/CustomerGroup')),
  },
  {
    path: `/${RouterPath.LoyaltyPointsHistory}/:id`,
    title: L('Loyalty points history'),
    name: `${RouterPath.LoyaltyPointsHistory}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/LoyaltyPointsHistory')),
  },
  {
    path: `/${RouterPath.LoyaltyPointsHistoryList}`,
    title: L('Loyalty points history list'),
    name: `${RouterPath.LoyaltyPointsHistoryList}`,
    icon: '',
    showInMenu: AppConsts.allowedContent === 'ALL' && (!isConfigForAG(AppConsts) && !isConfigForProduction(AppConsts)) ? true : false,
    component: LoadableComponent(() => import('../../scenes/LoyaltyPointsHistoryList')),
  },
  {
    path: `/${RouterPath.ProductType}/:id`,
    title: L('Product type'),
    name: `${RouterPath.ProductType}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/ProductType')),
  },
  {
    path: `/${RouterPath.ProductList}`,
    title: L('Products'),
    name: `${RouterPath.ProductList}`,
    icon: '',
    showInMenu: AppConsts.allowedContent === 'ALL' ? true : false,
    component: LoadableComponent(() => import('../../scenes/ProductList')),
  },
  {
    path: `/${RouterPath.Product}/:id`,
    title: L('Product'),
    name: `${RouterPath.Product}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Product')),
  },
  {
    path: `/${RouterPath.PolicyCalculation}`,
    title: L('New calculation'),
    name: `${RouterPath.PolicyCalculation}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/PolicyCalculation')),
  },
  {
    path: `/${RouterPath.PolicyForm}`,
    title: L('New policy'),
    name: `${RouterPath.PolicyForm}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/PolicyForm')),
  },
  {
    path: `/${RouterPath.ApkList}`,
    title: L('APK'),
    name: `${RouterPath.ApkList}`,
    icon: '',
    showInMenu: AppConsts.allowedContent === 'ALL' ? true : false,
    component: LoadableComponent(() => import('../../scenes/ApkList')),
  },
  // {
  //   path: `/${RouterPath.Apk}/:id`,
  //   title: L('APK'),
  //   name: `${RouterPath.Apk}`,
  //   icon: '',
  //   showInMenu: false,
  //   component: LoadableComponent(() => import('../../scenes/Apk')),
  // },
  {
    path: `/${RouterPath.ApkForm}`,
    title: L('New APK'),
    name: `${RouterPath.ApkForm}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/ApkForm')),
  },
  {
    path: `/${RouterPath.OcTerminationList}`,
    title: L('OC termination list'),
    name: `${RouterPath.OcTerminationList}`,
    icon: '',
    showInMenu: AppConsts.allowedContent === 'ALL' ? true : false,
    component: LoadableComponent(() => import('../../scenes/OcTerminationList')),
  },
  {
    path: `/${RouterPath.OcTermination}/:id`,
    title: L('OC termination'),
    name: `${RouterPath.OcTermination}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/OcTermination')),
  },
  {
    path: `/${RouterPath.InsuranceCompanyList}`,
    title: L('Insurance company list'),
    name: `${RouterPath.InsuranceCompanyList}`,
    icon: '',
    showInMenu: AppConsts.allowedContent === 'ALL' ? true : false,
    component: LoadableComponent(() => import('../../scenes/InsuranceCompanyList')),
  },
  {
    path: `/${RouterPath.InsuranceCompany}/:id`,
    title: L('Insurance company'),
    name: `${RouterPath.InsuranceCompany}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/InsuranceCompany')),
  },
  {
    path: `/${RouterPath.InsurancePolicyList}`,
    title: L('Insurance policies'),
    name: `${RouterPath.InsurancePolicyList}`,
    icon: '',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/InsurancePolicyList')),
  },
  {
    path: `/${RouterPath.InsurancePolicy}/:id`,
    title: L('Insurance policy'),
    name: `${RouterPath.InsurancePolicy}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/InsurancePolicy')),
  },
  {
    path: `/${RouterPath.OrderList}`,
    title: L('Order list'),
    name: `${RouterPath.OrderList}`,
    icon: '',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/OrderList')),
  },
  {
    path: `/${RouterPath.Order}/:id`,
    title: L('Order'),
    name: `${RouterPath.Order}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Order')),
  },
  {
    path: `/${RouterPath.VehicleList}`,
    title: L('Vehicles'),
    name: `${RouterPath.VehicleList}`,
    icon: '',
    showInMenu: AppConsts.allowedContent === 'ALL' ? true : false,
    component: LoadableComponent(() => import('../../scenes/VehicleList')),
  },
  {
    path: `/${RouterPath.Vehicle}/:id`,
    title: L('Vehicle'),
    name: `${RouterPath.Vehicle}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Vehicle')),
  },
  {
    path: `/${RouterPath.ClaimList}`,
    title: L('Claims'),
    name: `${RouterPath.ClaimList}`,
    icon: '',
    showInMenu: AppConsts.allowedContent === 'ALL' ? true : false,
    component: LoadableComponent(() => import('../../scenes/ClaimList')),
  },
  {
    path: `/${RouterPath.Claim}/:id/:policyType`,
    title: L('Claim'),
    name: `${RouterPath.Claim}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Claim')),
  },
  {
    path: `/${RouterPath.AgentClaimList}`,
    title: L('Agent claim list'),
    name: `${RouterPath.AgentClaimList}`,
    icon: '',
    showInMenu: AppConsts.allowedContent === 'ALL' ? true : false,
    component: LoadableComponent(() => import('../../scenes/AgentClaimList')),
  },
  {
    path: `/${RouterPath.AgentClaim}/:id/:policyType`,
    title: L('Agent claim'),
    name: `${RouterPath.AgentClaim}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/AgentClaim')),
  },
  {
    path: `/${RouterPath.UserList}`,
    title: L('User list'),
    name: `${RouterPath.UserList}`,
    icon: '',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/UserList')),
  },
  {
    path: `/${RouterPath.RegisteredUser}/:id`,
    title: L('User'),
    name: `${RouterPath.RegisteredUser}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/User')),
  },
  {
    path: `/${RouterPath.RoleList}`,
    title: L('Role list'),
    name: `${RouterPath.RoleList}`,
    icon: '',
    showInMenu: AppConsts.allowedContent === 'ALL' ? true : false,
    component: LoadableComponent(() => import('../../scenes/RoleList')),
  },
  {
    path: `/${RouterPath.Role}/:id`,
    title: L('Role'),
    name: `${RouterPath.Role}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Role')),
  },
  {
    path: `/${RouterPath.PatternList}`,
    title: L('Pattern list'),
    name: `${RouterPath.PatternList}`,
    icon: '',
    showInMenu: AppConsts.allowedContent === 'ALL' ? true : false,
    component: LoadableComponent(() => import('../../scenes/PatternList')),
  },
  {
    path: `/${RouterPath.Pattern}/:id`,
    title: L('Pattern'),
    name: `${RouterPath.Pattern}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Pattern')),
  },
  {
    path: `/${RouterPath.RatingList}`,
    title: L('Rating list'),
    name: `${RouterPath.RatingList}`,
    icon: '',
    showInMenu: AppConsts.allowedContent === 'ALL' && (!isConfigForAG(AppConsts) && !isConfigForProduction(AppConsts)) ? true : false,
    component: LoadableComponent(() => import('../../scenes/RatingList')),
  },
  {
    path: `/${RouterPath.Rating}/:id`,
    title: L('Rating'),
    name: `${RouterPath.Rating}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Rating')),
  },
  {
    path: `/${RouterPath.CommunityList}`,
    title: L('Community list'),
    name: `${RouterPath.CommunityList}`,
    icon: '',
    showInMenu: AppConsts.allowedContent === 'ALL' && (!isConfigForAG(AppConsts) && !isConfigForProduction(AppConsts)) ? true : false,
    component: LoadableComponent(() => import('../../scenes/CommunityList')),
  },
  {
    path: `/${RouterPath.Community}/:id`,
    title: L('Community'),
    name: `${RouterPath.Community}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Community')),
  },
  {
    path: `/${RouterPath.TestSetList}`,
    title: L('TestSet list'),
    name: `${RouterPath.TestSetList}`,
    icon: '',
    showInMenu: AppConsts.allowedContent === 'ALL' ? true : false,
    component: LoadableComponent(() => import('../../scenes/TestSetList')),
  },
  {
    path: `/${RouterPath.TestSet}/:id`,
    title: L('TestSet'),
    name: `${RouterPath.TestSet}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/TestSet')),
  },
  {
    path: `/${RouterPath.About}`,
    title: L('About'),
    name: `${RouterPath.About}`,
    icon: 'Info',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/About')),
  },
  {
    path: `/${RouterPath.Settings}`,
    title: L('Settings'),
    name: `${RouterPath.Settings}`,
    icon: 'Settings',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/Settings')),
  },
  {
    path: '/logout',
    permission: '',
    title: 'Logout',
    name: 'logout',
    icon: 'info-circle',
    showInMenu: false,
    component: LoadableComponent(() => import('../../components/Logout')),
  },
  {
    path: '/exception?:type',
    permission: '',
    title: 'exception',
    name: 'exception',
    icon: 'info-circle',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Exception')),
  },  
  {
    path: `/${RouterPath.Profile}`,
    title: L('Profile'),
    name: `${RouterPath.Profile}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Profile')),
  },
];

export const routers = [...userRouter, ...appRouters];
