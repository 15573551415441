import ContestService from '../services/contest/contestService';
import { ContestDto } from '../services/contest/dto/contestDto';
import { CrudStoreBase } from './crudStoreBase';

class ContestStore extends CrudStoreBase<ContestDto>{
  constructor() {
    super(ContestService, defaultContest)
  }

  public async create(createUserInput: ContestDto) {
    return ContestService.create(createUserInput);
  }

  public async update(updateUserInput: ContestDto) {
    return ContestService.create(updateUserInput);
  }
}

export const defaultContest = {
  id: '',
  name: '',
  productType: '',
  prizesAmount: 0,
  prizeItems: [],
  infinitePrizes: false,
  conditions: '',
  promotionRules: '',
  isEnabled: false,
  startDate: '',
  endDate: '',
  marketingAgreementsContent: '',
  contestDescription: '',
  contestPrizeDescription: '',
}

export default ContestStore;