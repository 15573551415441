import { IChoiceGroupOption, Icon, IStackStyles, IStackTokens, mergeStyleSets, Stack } from "@fluentui/react";
import React from "react";
import { LabelComponent } from "./labelComponent";
import {myTheme} from "../../styles/theme";
import { getAbpLanguage } from "../../utils/languageUtils";
import { Controls } from "./controls";
import { MultiDropdownBase } from "./multiDropdownBase";
import { CheckBoxBase, DropdownBase, TextFieldBase } from ".";
import { CheckBoxOptions } from "./CheckBoxOptions";
import { ChoiceGroupBase } from "./ChoiceGroupBase";
import { TimePicker } from "./timePicker";
import { DatePickerBase } from "./datePickerBase";
import { isJsonString } from "../../utils/utils";
import { gnInputIconStyles } from "../../styles/gnInputIconStyles";

const classNames = mergeStyleSets({
    stackWrapper: {
        marginTop: '20px',
        width: '100%'
    },
    stackMain: {
        marginTop: '10px',
        width: 'fit-content',
        minWidth: '500px',
        maxWidth: '100%',
        borderTop: `1px solid ${myTheme.palette.neutralSecondary}`,
        overflowX: 'auto',
        borderRight: `1px solid ${myTheme.palette.neutralSecondary}`,
        borderLeft: `1px solid ${myTheme.palette.neutralSecondary}`,
    },
    stackHeader: {
        alignItems: 'stretch',
    },
    stackHorizontal: {
        alignItems: 'stretch',
    },
    tableCell: {
        display: 'flex',
        flex: 'auto',
        margin: '0',
        padding: '10px',
        borderRight: `1px solid ${myTheme.palette.neutralSecondary}`,
        borderBottom: `1px solid ${myTheme.palette.neutralSecondary}`,
        textAlign: 'center',
        boxSizing: 'border-box',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '170px !important',
    },
    tableCellFirst: {
        borderLeft: 'none !important',
    },
    tableCellLast: {
        borderRight: 'none !important',
    },
    headerName: {
        background: myTheme.palette.neutralLighter,
        wordBreak: 'break-word',
    },
    textFieldBaseForTable: {
        width: '100%',
        selectors: {
            '& .ms-TextField-field': {
                width: '100%',
                textAlign: 'center',
            }
        }
    },
    datePickerForTable: {
        width: 'auto !important',
    },
    choiceGroupForTable: {
        selectors: {
            '& .ms-ChoiceField': {
                marginTop: '2px',
                marginBottom: '2px',
            },
            '& .ms-ChoiceFieldGroup-flexContainer': {
                alignItems: 'center',
            }
        }
    },
    customDropdownBase: {
        selectors: {
            '& .ms-Dropdown': {
                width: 'auto !important',
                minWidth: '160px',
            }
        }
    }
});

const stackStyles: IStackStyles = {
    root: {
        height: 'auto',
    },
};

const stackTokens: IStackTokens = { childrenGap: 0 };

export interface ITableInputsBaseProps {
    label: string;
    options: any;
    inputsForTable: any[];
    value: any;
    rowsCount?: number;
    customClassNames?: string;
    isDataLoaded?: boolean;
    iconName?: string;
    iconOnClick?: (iconIndex?: number) => void;
    onChange?: (jsonValue: string) => void;
}

export interface ITableInputsState {
    value: string | undefined,
}

export class TableInputsBase extends React.Component<ITableInputsBaseProps, ITableInputsState> {
    constructor(props: ITableInputsBaseProps) {
        super(props);

        this.state = {
            value: this.props.value && typeof this.props.value === 'string' && isJsonString(this.props.value) 
                    ? JSON.parse(this.props.value)
                    : (this.props.value && typeof this.props.value === 'object' ? this.props.value : {})
        };
    }

    private parseHeaders(): JSX.Element {
        let headerNames: string[] = [];

        if(this.props.options.tableColumnHeaders) {
            headerNames = this.props.options.tableColumnHeaders.split(';;');

            headerNames.forEach((headerName, i) => {
                let splittedHeaderNames = headerName.split(';');
                splittedHeaderNames.forEach((splittedHeaderName) => {
                    if(splittedHeaderName.length > 0) {
                        let translatedNames = splittedHeaderName.split('=');
                        if(translatedNames[0].toLowerCase() === getAbpLanguage()) {
                            headerNames[i] = translatedNames[1];
                        }
                    } else {
                        headerNames.splice(i, 1);
                    }
                });
            });
        }

        if(headerNames.length > 0) {
            let headerNamesJsx = headerNames.map((name: string, index: number) => {
                if(name.length > 0) {
                    return <h4 key={index} style={{width: `${100 / headerNames.length}%`}} className={`${classNames.tableCell} ${classNames.headerName} ${index === 0 && classNames.tableCellFirst} ${index === (headerNames.length - 1) ? classNames.tableCellLast : ''}`}>{name}</h4>;
                }
                return '';
            });

            return <Stack key="headerStack" horizontal verticalAlign="start" className={classNames.stackHeader} styles={stackStyles}>
                {headerNamesJsx}
            </Stack>;
        } else {
            return <></>;
        }
    }

    private sortInputs(inputsIdInOrder: string[], inputsForTable: any[]): any[] {
        let sortedInputsArray: any[] = [];

        if(inputsIdInOrder && inputsIdInOrder.length > 0 && inputsForTable && inputsForTable.length > 0) {
            let i = 0;
            while(sortedInputsArray.length !== inputsIdInOrder.length && i < 100) {
                let idToSearch = inputsIdInOrder[i];
    
                const filteredInput = inputsForTable.filter((property: any) => property.id === idToSearch);
                if(filteredInput.length > 0) {
                    sortedInputsArray.push(filteredInput[0]);
                }

                i++;
            }
        }

        return sortedInputsArray;
    }
    
    private handleInputChange(row: number, id: string, value: any) {
        let jsonValue: any = this.state.value;
        
        if(!jsonValue[row]) {
            jsonValue[row] = {};
        }
        jsonValue[row][id] = value;

        this.setState((prevState) => ({...prevState, value: jsonValue }));

        if(typeof this.props.onChange === 'function') {
            this.props.onChange(JSON.stringify(jsonValue));
        }
    }

    private checkIfValueChanged(predefinedValue: string | undefined): any {
        let stateValue = this.state && this.state.value ? (isJsonString(this.state.value) ? this.state.value : JSON.stringify(this.state.value)) : ""; 

        if(predefinedValue && predefinedValue.length > 0 && (!this.state || stateValue !== predefinedValue)) {
            const newValue: any = isJsonString(predefinedValue) ? JSON.parse(predefinedValue) : {};
            this.setState((prevState) => ({...prevState, value: newValue }));

            if(typeof this.props.onChange === 'function') {
                this.props.onChange(JSON.stringify(newValue));
            }

            return newValue;
        }
        return JSON.parse(stateValue);
    }

    render() {
        const { rowsCount, options, inputsForTable, label, value } = this.props;

        let rows: JSX.Element[] = [this.parseHeaders()];
        let rowsCountBase = 0;
        let inputsIdInOrder = options.tableColumnInputs.split(";;");
        
        if(rowsCount && rowsCount >= 0) {
            rowsCountBase = rowsCount;

            if(rowsCountBase > 50) {
                rowsCountBase = 50; // TODO dev, delete later, make pagination or something
            }
        }

        let parsedValue = isJsonString(value) ? JSON.parse(value) : value;
        for(let key in parsedValue) {
            if(parsedValue.hasOwnProperty(key)) {
                if(parseInt(key) > rowsCountBase - 1) {
                    delete parsedValue[parseInt(key)];
                }
            }
        }
        
        // this.checkIfValueChanged(JSON.stringify(parsedValue));
        // let newValue = this.state ? this.state.value : (isJsonString(value) ? JSON.parse(value) : {});
        let newValue: any = this.checkIfValueChanged(JSON.stringify(parsedValue));
        if(!newValue) newValue = {};
        let sortedInputsForTable = this.sortInputs(inputsIdInOrder, inputsForTable);

        for(let i = 0; i < rowsCountBase; i++) {
            rows.push(
                <Stack key={i} horizontal verticalAlign="start" styles={stackStyles} className={classNames.stackHorizontal}>
                    {sortedInputsForTable.map((property: any, index: number) => {
                        if(property.additionalOptions.validationData && property.additionalOptions.validationData.autoincrement
                            && property.additionalOptions.validationData.autoincrement === 'true' &&
                            (typeof newValue[i] === 'undefined' || typeof newValue[i][property.id] === 'undefined')
                        ) {
                            this.handleInputChange(i, property.id, `${i + 1}`);
                        }

                        if(property.type === 'ChoiceGroup') {
                            let newOptions: IChoiceGroupOption[] = [];

                            property.options.choicegroup.forEach((option: any) => {
                                if(newValue[i] && newValue[i][property.id]) {
                                    newOptions.push({...option, checked: option.key === newValue[i][property.id]});
                                } else {
                                    newOptions.push({...option, checked: false});
                                }
                            });
                            property.options.choicegroup = newOptions;
                        }

                        return <div key={index} style={{width: `${100 / inputsForTable.length}%`}} className={`${classNames.tableCell} ${index === 0 && classNames.tableCellFirst} ${index === inputsForTable.length - 1 ? classNames.tableCellLast : ''}`}>
                            { this.renderElement(i, property, newValue) }
                        </div>;
                    }) }
                </Stack>
            );
        }

        let icons: any = <></>;

        if(this.props.iconName) {
            if(!isJsonString(this.props.iconName)) {
                icons = <Icon iconName={this.props.iconName} className={gnInputIconStyles.icon} onClick={() => this.props.iconOnClick!()} />;
            } else {
                let parsedJsonString: any = JSON.parse(this.props.iconName);
                if(parsedJsonString && Array.isArray(parsedJsonString)) {
                    icons = parsedJsonString.map((iconName: string, index: number) => {
                            return <Icon iconName={iconName} className={gnInputIconStyles.icon} onClick={() => this.props.iconOnClick!(index)} /> 
                        });
                }
            }
        }

        return <div className={classNames.stackWrapper} key={`${label.split(' ').join('_').toLowerCase()}_${Math.floor(Math.random())}`}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <LabelComponent label={label} />
                {icons}
            </div>

            <Stack className={classNames.stackMain} tokens={stackTokens}>
                {rows}
            </Stack>
        </div>;
    }

    private renderElement(row: number, element: any, value: any) {
        if((!value[row] || !value[row][element.id]) && element.additionalOptions && element.additionalOptions.validationData && element.additionalOptions.validationData.defaultValue) {
            this.handleInputChange(row, element.id, element.additionalOptions.validationData.defaultValue);
        }

        switch (element.type) {
            case Controls.Text:
                return <TextFieldBase key={element.id} required={element.isRequired} customClassNames={`${classNames.textFieldBaseForTable}`}
                    rows={element.additionalOptions.rows} multiline={element.additionalOptions.rows > 1} value={value[row] && value[row][element.id]} type={element.additionalOptions.textType}
                    disabled={element.disabled} isDataLoaded={element.additionalOptions.isDataLoaded} validationData={element.additionalOptions.validationData}
                    onChange={(e: any) => {
                        this.handleInputChange(row, element.id, (e.target && e.target.value ? e.target.value : (typeof e === 'string' ? e : '')));
                    }} />;
            case Controls.Date:
                return <DatePickerBase key={element.id} required={element.isRequired} label={''} value={value[row] && value[row][element.id]}
                    disabled={element.disabled} customClassNames={`${classNames.datePickerForTable}`} isDataLoaded={element.additionalOptions.isDataLoaded}
                    onChange={(e: any) => {
                        this.handleInputChange(row, element.id, e);
                    }} />;
            case Controls.Time:
                return <TimePicker key={element.id} label={''} value={value[row] && value[row][element.id]} disabled={element.disabled} 
                    isDataLoaded={element.additionalOptions.isDataLoaded}
                    onChange={(e) => {
                        this.handleInputChange(row, element.id, e);
                    }} />;
            case Controls.Picker:
                return <DropdownBase key={element.id} required={element.isRequired} label={''} options={element.options.dropdown} value={value[row] && value[row][element.id]}
                    disabled={element.disabled} customClassName={classNames.customDropdownBase} isDataLoaded={element.additionalOptions.isDataLoaded}
                    onChange={(e: any) => {
                        this.handleInputChange(row, element.id, e);
                    }} />;
            case Controls.MultiPicker:
                return <MultiDropdownBase key={element.id} label={''} options={element.options.dropdown} value={value[row] && value[row][element.id]}
                    disabled={element.disabled} isDataLoaded={element.additionalOptions.isDataLoaded}
                    onChange={(e: any) => {
                        this.handleInputChange(row, element.id, e);
                    }} />;
            case Controls.CheckBox:
                return <CheckBoxBase key={element.id} label={''} value={value[row] && value[row][element.id]}
                    disabled={element.disabled}
                    onChange={(e: any) => {
                        this.handleInputChange(row, element.id, e);
                    }} />;
            case Controls.CheckBoxOptions:
                return <CheckBoxOptions key={element.id} label={''} options={element.options.dropdown}
                    disabled={element.disabled}
                    onChange={(e: any) => {
                        this.handleInputChange(row, element.id, e);
                    }}
                />;
            case Controls.ChoiceGroup:
                return <ChoiceGroupBase key={element.id} label={''} value={value[row] && value[row][element.id]} customClassNames={`${classNames.choiceGroupForTable}`}
                    disabled={element.disabled} options={element.options.choicegroup}
                    onChange={(e) => {
                        this.handleInputChange(row, element.id, (e && e.key ? e.key : e));
                    }}
                />;
            default:
                return;
        }
    }
}